const frontPage = () => {
    // Services bottom
    let serviceBottom = document.querySelector(".service__bottom");
    if (serviceBottom) {
        const serviceBottomTl = gsap.timeline({
            scrollTrigger: {
                trigger: serviceBottom,
                start: "0 70%",
            },
        });

        serviceBottomTl
            .from(".service__bottom--icon div", {
                y: 50,
                opacity: 0.5,
                duration: 0.5,
                stagger: 0.2,
            })
            .from(
                ".service__bottom--title div",
                {
                    y: 25,
                    duration: 0.75,
                    ease: "power1.out",
                    stagger: 0.2,
                },
                "<"
            )
            .from(
                ".service__bottom--content",
                {
                    y: 25,
                    opacity: 0,
                    duration: 1,
                    ease: "power1.out",
                    stagger: 0.2,
                },
                "<"
            );
    }

    // SEO
    let seo = document.querySelector(".seo");
    if (seo) {
        const seoTl = gsap.timeline({
            scrollTrigger: {
                trigger: seo,
                start: "0 60%",
            },
        });

        seoTl
            .from('.seo__city', {
                opacity: 0,
                stagger: .1
            })
    }
};

export default frontPage;
